.hero-section {
    position: relative;
    padding-top: 80px;
    padding-bottom: 130px;
}

.hero-section::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 1) 100%); */
}


div:focus {
    outline: none;
}

/* Adjustments for mobile view */
@media (max-width: 768px) {
    .hero-section {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .hero-section::after {
        background: none; 
    }

    .hero-section .container {
        margin-top: 0;
        margin-bottom: 0;
    }

    .hero-section h1 {
        margin-top: 0;
        font-size: 2rem; 
    }

    .hero-section .md\:mt-8 {
        margin-top: 12px;
    }

    .hero-section .md\:ml-14 {
        margin-left: 0;
    }

    .hero-section .md\:w-1\/2 {
        width: 100%;
    }

    .hero-section .relative {
        top: 0;
    }

    .address-container {
        padding-right: 16px; 
    }

    .footnav-links {
        padding-left: 10px;
    }

    .animate-marquee {
        animation: marquee 60s linear infinite;
    }   
}